
import { blogData } from '../blogData';
import useMetaTags from './metatags';

function Blog() {
	useMetaTags(
		'News &amp; Blog - Estate Agency Essex | Blake &amp; Thickbroom',
		'Blake &amp; Thickbroom Is A Leading Estate Agency Based In Clacton. Buy, Sell, Rent Or Let With Blake &amp; Thickbroom. Read Our News &amp; Blog Today'
	);
	return (
		<div>
			<section className="breadcumb-blog p-0">
				<div className="container">
					<div className="row">
						<div
							id="mobile"
							className="col-lg-8 mt50">
							<div id="mobile" className="breadcumb-style1">
								<h2 className="title">Blake &amp; Thickbroom Blog</h2>
								<p>See the our latest news and information...</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="our-blog pt-0">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-xl-12">
							<div className="navpill-style1">
								<div
									className="tab-content"
									id="pills-tabContent">
									<div className="row">
										{blogData.map((blog) => (
											<div className="col-sm-6 col-lg-4">
												<div
													className="blog-style1"
													key={blog.id}>
													<div className="blog-img">
														<img
															className="w-100"
															src={`${blog.image}`}
															alt="{blog.title}"
														/>
													</div>
													<div className="blog-content">
														<div className="date">
															<span className="day">{blog.day}</span>
															<span className="month">{blog.month}</span>
														</div>
														<h6 className="title mt-1">
															<a
																href={`/blog/${blog.id}_${blog.title
																	.replace(/\s+/g, '-')
																	.replace('%', '-')}`}>
																{blog.title}
															</a>
														</h6>
														<p>{blog.intro}</p>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Blog

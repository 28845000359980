import useMetaTags from './metatags';

function CookiePolicy() {

	useMetaTags(
		'Cookie Policy | Blake &amp; Thickbroom',
		'Blake &amp; Thickbroom cookie policy'
	);

	return (
		<div>
			<section className="breadcumb-section2 p-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcumb-style1">
								<h2 className="title">Cookie Policy</h2>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-12">
							<h2>Cookie Policy</h2>
						</div>
						<div className="col-lg-12">
							<div className="page-content-wrap">
								<p>
									This website www.blake-thickbroom.co.uk (“Our Site”) uses
									Cookies and similar technologies in order to distinguish you
									from other users. By using Cookies, We are able to provide you
									with a better experience and to improve Our Site by better
									understanding how you use it. Please read this Cookie Policy
									carefully and ensure that you understand it. Your acceptance
									of Our Cookie Policy is deemed when you click the accept
									checkbox and then select the continue button on Our Cookie
									prompt at the footer of all pages. If you do not agree to Our
									Cookie Policy, please stop using Our Site immediately.
								</p>
								<div>
									<h2>1. Definitions and Interpretation</h2>
									<p>
										<strong>1.1</strong>&nbsp;In this Cookie Policy, unless the
										context otherwise requires, the following expressions have
										the following meanings:
									</p>
									<p>
										<strong>“Cookie”</strong>&nbsp;- means a small file placed
										on your computer or device by Our Site when you visit
										certain parts of Our Site and/or when you use certain
										features of Our Site;
									</p>
									<p>
										<strong>“Cookie Law”</strong>&nbsp;- means the relevant
										parts of the Privacy and Electronic Communications (EC
										Directive) Regulations 2003 and of EU Regulation 2016/679
										General Data Protection Regulation (“GDPR”);
									</p>
									<p>
										<strong>“personal data”&nbsp;</strong>- means any and all
										data that relates to an identifiable person who can be
										directly or indirectly identified from that data, as defined
										by the Data Protection Act 1998 or EU Regulation 2016/679
										General Data Protection Regulation (“GDPR”);
									</p>
									<p>
										<strong>“We/Us/Our”</strong>&nbsp;- means Blake &amp;
										Thickbroom Estate Agents, whose main trading address is 70
										Station Road, Clacton-on-Sea, Essex, CO15 1SP.
									</p>
									<h2>2. Information About Us</h2>
									<p>
										<strong>2.1&nbsp;</strong>Our Site is owned and operated by
										Blake &amp; Thickbroom Estate Agents, whose main trading
										address is 70 Station Road, Clacton-on-Sea, Essex, CO15 1SP.
									</p>
									<p>
										<strong>2.2</strong>&nbsp;Our Data Protection Officer can be
										contacted by email at&nbsp;
										<a href="mailto:dataprotection@blake-thickbroom.co.uk">
											dataprotection@
										</a>
										<a href="mailto:dataprotection@blake-thickbroom.co.uk">
											blake-thickbroom.co.uk
										</a>
										, by telephone on 01255 688788.
									</p>
									<h2>3. How Does Our Site Use Cookies?</h2>
									<p>
										<strong>3.1</strong>&nbsp;Our Site may place and access
										certain first party Cookies on your computer or device.
										First party Cookies are those placed directly by Us and are
										used only by Us. We use Cookies to facilitate and improve
										your experience of Our Site and to provide and improve Our
										products or services. We have carefully chosen these Cookies
										and have taken steps to ensure that your privacy and
										personal data is protected and respected at all times.
									</p>
									<p>
										<strong>3.2</strong>&nbsp;By using Our Site, you may also
										receive certain third party Cookies on your computer or
										device. Third party Cookies are those placed by websites,
										services, and/or parties other than Us. Third party Cookies
										are used on Our Site for analytics and the live chat/message
										functions. For more details, please refer to section 4
										below.
									</p>
									<p>
										<strong>3.3</strong>&nbsp;All Cookies used by and on Our
										Site are used in accordance with current Cookie Law. We may
										use some or all of the following types of Cookie:
									</p>
									<p>
										<strong>3.3.1</strong>&nbsp;Strictly Necessary Cookies - A
										Cookie falls into this category if it is essential to the
										operation of Our Site, supporting functions such as logging
										in, your shopping basket, and payment transactions.
									</p>
									<p>
										<strong>3.3.2</strong>&nbsp;Analytics Cookies - It is
										important for Us to understand how you use Our Site, for
										example, how efficiently you are able to navigate around it,
										and what features you use. Analytics Cookies enable us to
										gather this information, helping Us to improve Our Site and
										your experience of it.
									</p>
									<p>
										<strong>3.3.3</strong>&nbsp;Functionality Cookies -
										Functionality Cookies enable Us to provide additional
										functions to you on Our Site such as personalisation and
										remembering your saved preferences. Some functionality
										Cookies may also be strictly necessary Cookies, but not all
										necessarily fall into that category.
									</p>
									<p>
										<strong>3.3.4</strong>&nbsp;Targeting Cookies - It is
										important for Us to know when and how often you visit Our
										Site, and which parts of it you have used (including which
										pages you have visited and which links you have visited). As
										with analytics Cookies, this information helps us to better
										understand you and, in turn, to make Our Site and
										advertising more relevant to your interests. Some
										information gathered by targeting Cookies may also be shared
										with third parties.
									</p>
									<p>
										<strong>3.3.5</strong>&nbsp;Third Party Cookies - Third
										party Cookies are not placed by Us; instead, they are placed
										by third parties that provide services to Us and/or to you.
										Third party Cookies may be used by advertising services to
										serve up tailored advertising to you on Our Site, or by
										third parties providing analytics services to Us (these
										Cookies will work in the same way as analytics Cookies
										described above).
									</p>
									<p>
										<strong>3.3.6</strong>&nbsp;Persistent Cookies - Any of the
										above types of Cookie may be a persistent Cookie. Persistent
										Cookies are those which remain on your computer or device
										for a predetermined period and are activated each time you
										visit Our Site.
									</p>
									<p>
										<strong>3.3.7</strong>&nbsp;Session Cookies - Any of the
										above types of Cookie may be a session Cookie. Session
										Cookies are temporary and only remain on your computer or
										device from the point at which you visit Our Site until you
										close your browser. Session Cookies are deleted when you
										close your browser.
									</p>
									<p>
										<strong>3.4</strong>&nbsp;Cookies on Our Site are not
										permanent and will expire as indicated in the table below.
									</p>
									<p>
										<strong>3.5</strong>&nbsp;For more details of the personal
										data that We collect and use, the measures we have in place
										to protect personal data, your legal rights, and our legal
										obligations, please refer to our Privacy Policy&nbsp;
										<a href="https://www.blake-thickbroom.co.uk/privacy-policy/">
											https://www.blake-thickbroom.co.uk/privacy-policy/
										</a>
									</p>
									<p>
										<strong>3.6</strong>&nbsp;For more specific details of the
										Cookies that We use, please refer to the table below.
									</p>
									<h2>4. What Cookies Does Our Site Use?</h2>
									<p>
										<strong>4.1</strong>&nbsp;The following first party Cookies
										may be placed on your computer or device:
									</p>
									<table>
										<tbody>
											<tr>
												<td>
													<strong>Name of Cookie</strong>
												</td>
												<td>
													<strong>Purpose &amp; Type</strong>
												</td>
												<td>
													<strong>Strictly Necessary</strong>
												</td>
											</tr>
											<tr>
												<td>ASP.NET_SessionId</td>
												<td>
													This is a session cookie that our web site uses to
													store a unique identifier while you are using our web
													site. The cookie is not stored permanently on your
													hard disk. It is only there while you are using our
													web site.
												</td>
												<td>yes</td>
											</tr>
											<tr>
												<td>cc_cookie_accept</td>
												<td>
													Used to decide if we show the accept cookies prompt.
												</td>
												<td>yes</td>
											</tr>
											<tr>
												<td>cc_cookie_decline</td>
												<td>
													Used to decide if we show the accept cookies prompt.
												</td>
												<td>yes</td>
											</tr>
										</tbody>
									</table>
									<p>
										<strong>4.2</strong>&nbsp;Our Site uses analytics services
										provided by Google. Website analytics refers to a set of
										tools used to collect and analyse anonymous usage
										information, enabling Us to better understand how Our Site
										is used. This, in turn, enables Us to improve Our Site and
										the products or services offered through it. You do not have
										to allow Us to use these Cookies, however whilst Our use of
										them does not pose any risk to your privacy or your safe use
										of Our Site, it does enable Us to continually improve Our
										Site, making it a better and more useful experience for you.
									</p>
									<p>
										<strong>4.3</strong>&nbsp;The analytics service(s) used by
										Our Site use(s) analytics Cookies to gather the required
										information.
									</p>
									<p>
										<strong>4.4</strong>&nbsp;The analytics service(s) used by
										Our Site use(s) the following analytics Cookies:
									</p>
									<table>
										<tbody>
											<tr>
												<td>
													<strong>Name of Cookie</strong>
												</td>
												<td>
													<strong>Provider</strong>
												</td>
												<td>
													<strong>Strictly Necessary</strong>
												</td>
											</tr>
											<tr>
												<td>_gid</td>
												<td>Google</td>
												<td>No</td>
											</tr>
											<tr>
												<td>_ga</td>
												<td>Google</td>
												<td>No</td>
											</tr>
											<tr>
												<td>__utmz</td>
												<td>Google</td>
												<td>No</td>
											</tr>
											<tr>
												<td>__utmc</td>
												<td>Google</td>
												<td>No</td>
											</tr>
											<tr>
												<td>__utmb</td>
												<td>Google</td>
												<td>No</td>
											</tr>
											<tr>
												<td>__utma</td>
												<td>Google</td>
												<td>No</td>
											</tr>
										</tbody>
									</table>
									<h2>5. Consent and Control</h2>
									<p>
										<strong>5.1</strong>&nbsp;Before Cookies are placed on your
										computer or device, you will be shown a prompt at the footer
										of the page requesting your consent to set those Cookies. By
										giving your consent to the placing of Cookies you are
										enabling Us to provide the best possible experience and
										service to you. You may, if you wish, deny consent to the
										placing of Cookies unless those Cookies are strictly
										necessary; however certain features of Our Site may not
										function fully or as intended.
									</p>
									<p>
										<strong>5.2</strong>&nbsp;In addition to the controls that
										We provide, you can choose to enable or disable Cookies in
										your internet browser. Most internet browsers also enable
										you to choose whether you wish to disable all Cookies or
										only third party Cookies. By default, most internet browsers
										accept Cookies but this can be changed. For further details,
										please consult the help menu in your internet browser or the
										documentation that came with your device.
									</p>
									<p>
										<strong>5.3</strong>&nbsp;The links below provide
										instructions on how to control Cookies in all mainstream
										browsers:
									</p>
									<p>
										<strong>5.3.1</strong>&nbsp;Google Chrome:&nbsp;
										<a
											href="https://support.google.com/chrome/answer/95647?hl=en-GB"
											target="_blank"
											rel="noopener">
											https://support.google.com/chrome/answer/95647?hl=en-GB
										</a>
									</p>
									<p>
										<strong>5.3.2</strong>&nbsp;Microsoft Internet
										Explorer:&nbsp;
										<a
											href="https://support.microsoft.com/en-us/kb/278835"
											target="_blank"
											rel="noopener">
											https://support.microsoft.com/en-us/kb/278835
										</a>
									</p>
									<p>
										<strong>5.3.3</strong>&nbsp;Microsoft Edge:&nbsp;
										<a
											href="https://support.microsoft.com/en-gb/products/microsoft-edge"
											target="_blank"
											rel="noopener">
											https://support.microsoft.com/en-gb/products/microsoft-edge
										</a>
										&nbsp;(Please note that there are no specific instructions
										at this time, but Microsoft support will be able to assist)
									</p>
									<p>
										<strong>5.3.4</strong>&nbsp;Safari (macOS):&nbsp;
										<a
											href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&amp;locale=en_GB"
											target="_blank"
											rel="noopener">
											https://support.apple.com/kb/PH21411?viewlocale=en_GB&amp;locale=en_GB
										</a>
									</p>
									<p>
										<strong>5.3.5</strong>&nbsp;Safari (iOS):&nbsp;
										<a
											href="https://support.apple.com/en-gb/HT201265"
											target="_blank"
											rel="noopener">
											https://support.apple.com/en-gb/HT201265
										</a>
									</p>
									<p>
										<strong>5.3.6</strong>&nbsp;Mozilla Firefox:&nbsp;
										<a
											href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences"
											target="_blank"
											rel="noopener">
											https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences
										</a>
									</p>
									<p>
										<strong>5.3.7</strong>&nbsp;Android:&nbsp;
										<a
											href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en"
											target="_blank"
											rel="noopener">
											https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en
										</a>
										&nbsp;(Please refer to your device's documentation for
										manufacturers' own browsers)
									</p>
									<h2>6. Changes to this Cookie Policy</h2>
									<p>
										<strong>6.1</strong>&nbsp;We may alter this Cookie Policy at
										any time. Any such changes will become binding on you on
										your first use of Our Site after the changes have been
										made.You are therefore advised to check this page from time
										to time.
									</p>
									<p>
										<strong>6.2</strong>&nbsp;In the event of any conflict
										between the current version of this Cookie Policy and any
										previous version(s), the provisions current and in effect
										shall prevail unless it is expressly stated otherwise.
									</p>
									<h2>7. Further Information</h2>
									<p>
										<strong>7.1</strong>&nbsp;If you would like to know more
										about how We use Cookies, please contact Us at&nbsp;
										<a href="mailto:enquiries@blake-thickbroom.co.uk">
											enquiries@blake-thickbroom.co.uk
										</a>
										,&nbsp;by telephone on 01255 688788.
									</p>
									<p>
										<strong>7.2</strong>&nbsp;For more information about
										privacy, data protection and our terms and conditions,
										please visit the following:
									</p>
									<p>
										<strong>7.2.1&nbsp;</strong>
										<a href="http://www.blake-thickbroom.co.uk/privacy-policy/">
											http://www.blake-thickbroom.co.uk/privacy-policy/
										</a>
										.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default CookiePolicy;

import React, { useEffect, useRef, useState } from 'react';

type PriceRange = {
	min: string;
	max: string;
};

type Filters = {
	listingStatus: string;
	propertyTypes: string;
	priceRange: PriceRange;
	bedrooms: string;
	sortOrder: string;
};

type FilterModalProps = {
	onSubmit: (filters: Filters) => void;
};

const FilterModal: React.FC<FilterModalProps> = ({ onSubmit }) => {
	const [priceRange, setPriceRange] = useState<PriceRange>({
		min: '0',
		max: '800000',
	});
	const [propertyType, setPropertyType] = useState<string>('');
	const [listingType, setListingType] = useState<string>('RS');
	const [sortOrder, setSortOrder] = useState<string>('HL');
	const [bedrooms, setBedrooms] = useState<string>('1');
	const dropdownRef = useRef<HTMLSelectElement | null>(null);

	const handleSubmit = () => {
		const filters: Filters = {
			listingStatus: listingType,
			propertyTypes: propertyType,
			priceRange: priceRange,
			bedrooms: bedrooms,
			sortOrder: sortOrder,
		};
		console.log('Submitting filters:', filters);
		onSubmit(filters);
	};

	return (
		<div className="advance-feature-modal">
			<div
				className="modal fade"
				id="SearchModal"
				tabIndex={-1}
				aria-labelledby="SearchModalLabel"
				aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<div className="modal-header pl30 pr30">
							<h5
								className="modal-title"
								id="SearchModalLabel">
								Search Options
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"></button>
						</div>
						<div className="modal-body pb-0">
							<div className="row">
								<div className="col-lg-12">
									<div className="widget-wrapper">
										<h6 className="list-title">Price Range</h6>
										<div className="range-slider-style large-version">
											<div className="range-wrapper">
												<div className="slider-range mb30 mt35"></div>
												<div className="text-center">
													<input
														type="text"
														className="amount"
														placeholder="£100,000"
														value={priceRange.min}
														onChange={(e) =>
															setPriceRange({
																...priceRange,
																min: e.target.value,
															})
														}
													/>
													<span className="fa-sharp fa-solid fa-minus mx-2 dark-color"></span>
													<input
														type="text"
														className="amount2"
														placeholder="£800,000"
														value={priceRange.max}
														onChange={(e) =>
															setPriceRange({
																...priceRange,
																max: e.target.value,
															})
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<div className="widget-wrapper">
										<h6 className="list-title">Property Type</h6>
										<select
											className="selectpicker"
											aria-label="Default select example">
											<option selected>Open this select menu</option>
											<option value="1">One</option>
											<option value="2">Two</option>
											<option value="3">Three</option>
										</select>
										<div className="form-style2 input-group">
											<select
												ref={dropdownRef}
												className="form-select"
												data-live-search="true"
												data-width="100%"
												value={propertyType}
												onChange={(e) => setPropertyType(e.target.value)}>
												<option value="">Property</option>
												<option value="Houses">House</option>
												<option value="Bungalows">Bungalow</option>
												<option value="Flats / Apartments">Flat</option>
												<option value="Retirement Property">
													Retirement Property
												</option>
												<option value="Mobile / Park Homes">
													Mobile / Park Homes
												</option>
												<option value="Land / Development">Land</option>
											</select>
										</div>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="widget-wrapper">
										<h6 className="list-title">Listing Type</h6>
										<div className="form-style2 input-group">
											<select
												ref={dropdownRef}
												className="form-select"
												data-live-search="true"
												data-width="100%"
												value={listingType}
												onChange={(e) => setListingType(e.target.value)}>
												<option value="">Type</option>
												<option value="RS">For Sale</option>
												<option value="RL">To Let</option>
												<option value="NW">New Homes</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<div className="widget-wrapper">
										<h6 className="list-title">Bedrooms</h6>
										<div className="d-flex">
											<div className="selection">
												<input
													id="xany"
													name="xbeds"
													type="radio"
													checked={bedrooms === '1'}
													onChange={() => setBedrooms('1')}
												/>
												<label htmlFor="xany">any</label>
											</div>
											<div className="selection">
												<input
													id="xoneplus"
													name="xbeds"
													type="radio"
													checked={bedrooms === '1+'}
													onChange={() => setBedrooms('1+')}
												/>
												<label htmlFor="xoneplus">1+</label>
											</div>
											<div className="selection">
												<input
													id="xtwoplus"
													name="xbeds"
													type="radio"
													checked={bedrooms === '2+'}
													onChange={() => setBedrooms('2+')}
												/>
												<label htmlFor="xtwoplus">2+</label>
											</div>
											<div className="selection">
												<input
													id="xthreeplus"
													name="xbeds"
													type="radio"
													checked={bedrooms === '3+'}
													onChange={() => setBedrooms('3+')}
												/>
												<label htmlFor="xthreeplus">3+</label>
											</div>
											<div className="selection">
												<input
													id="xfourplus"
													name="xbeds"
													type="radio"
													checked={bedrooms === '4+'}
													onChange={() => setBedrooms('4+')}
												/>
												<label htmlFor="xfourplus">4+</label>
											</div>
											<div className="selection">
												<input
													id="xfiveplus"
													name="xbeds"
													type="radio"
													checked={bedrooms === '5+'}
													onChange={() => setBedrooms('5+')}
												/>
												<label htmlFor="xfiveplus">5+</label>
											</div>
										</div>
									</div>
								</div>
								<div className="col-sm-6">
									<div className="widget-wrapper">
										<h6 className="list-title">Sort Order</h6>
										<select
											ref={dropdownRef}
											className="form-select"
											data-live-search="true"
											data-width="100%"
											value={listingType}
											onChange={(e) => setSortOrder(e.target.value)}>
											<option value="">Sort Order</option>
											<option value="LH">Price (Low - High)</option>
											<option value="HL">Price (High - Low)</option>
											<option value="BD">Beds</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer justify-content-between">
							<div className="btn-area">
								<button
									className="ud-btn btn-thm"
									onClick={handleSubmit}
									data-bs-dismiss="modal">
									<span className="flaticon-search align-text-top pr10"></span>
									Search
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FilterModal;
function componentDidMount() {
	throw new Error('Function not implemented.');
}

import YouTube from "react-youtube";
import VideoPlayer from "../video";

function HomeBanner() {
	return (
		<section className="home-banner-style1 p0">
				{/* <VideoPlayer/> */}
			<div className="home-style1">
				<div className="container">
					<div className="row">
						<div className="col-xl-11 mx-auto">
							<div className="inner-banner-style1 text-center">
								<h2 className="hero-title animate-up-2">
									Over 125 Years' Experience in Estate Agency
								</h2>
								<p className="hero-text fz15 animate-up-3">
									A Family-Run Estate Agent Specialising in Sales, Lettings,
									Land & New Homes.
								</p>
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-5 col-xl-5 wow fadeInLeft valuation">
							<div className="cta-style1">
								<h2 className="cta-title">Get a Free Instant Valuation</h2>
							</div>
							<div
								className="wow fadeInRight"
								data-wow-delay="100ms">
								<div className="cta-btns-style1 d-block d-sm-flex align-items-center justify-content-lg-end">
									<a
										href="https://valuation.blake-thickbroom.co.uk/home/3302"
										target="_blank"
										rel="noreferrer"
										className="ud-btn btn-dark">
										<span className="flaticon-search-1 vam pe-2"></span>Free
										Instant Valuation
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default HomeBanner;

import useMetaTags from './metatags';

function DataConsent() {

	useMetaTags(
		'Data Consent | Blake &amp; Thickbroom',
		'Blake &amp; Thickbroom Data Consent'
	);

	return (
		<div>
			<section className="breadcumb-section2 p-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcumb-style1">
								<h2 className="title">Data Consent</h2>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-12">
							<h2>Data Consent</h2>
						</div>
						<div className="col-lg-12">
							<p>
								By registering with us you have provided us with your personal
								information e.g. name, address email etc.. You have given verbal
								consent to us holding this information about you in our
								databases solely for our use and will only be used to provide a
								requested service. We do not sell this information, nor do we
								disclose the information to third parties, except where required
								by law. You may receive email, postal or telephone communication
								from us following your request for property updates.
							</p>
							<p>
								Any non-personal information such as IP address, pages accessed,
								and files downloaded will only be used to determine how many
								individuals use our site, how many people visit on a regular
								basis, which pages are most popular, and which pages are least
								popular. This information doesn't tell us anything about who you
								are or where you live, it is simply used to enable us to monitor
								and improve our service.
							</p>
							<p>
								<strong>Withdrawal of consent (Opt - Out)</strong>
							</p>
							<p>
								You have the right, at any time, to ask us not to process your
								personal data for marketing purpose and any additional service
								you have consented to receive.&nbsp; You can Opt Out of
								receiving any of these services by e-mailing us or contacting
								our office.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default DataConsent;

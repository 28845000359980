import { useEffect } from 'react';

const useMetaTags = (title?: string, description?: string) => {
	useEffect(() => {
		if (title) {
			document.title = `${title} - Blake & Thickbroom`;
		}

		if (description) {
			let metaDescriptionTag = document.querySelector(
				'meta[name="description"]'
			);
			if (metaDescriptionTag) {
				metaDescriptionTag.setAttribute('content', description);
			} else {
				const newMetaTag = document.createElement('meta');
				newMetaTag.setAttribute('name', 'description');
				newMetaTag.setAttribute('content', description);
				document.head.appendChild(newMetaTag);
			}
		}

		const canonicalUrl = window.location.href;
			let linkCanonicalTag = document.querySelector('link[rel="canonical"]');
			if (linkCanonicalTag) {
				linkCanonicalTag.setAttribute('href', canonicalUrl);
			} else {
				const newLinkTag = document.createElement('link');
				newLinkTag.setAttribute('rel', 'canonical');
				newLinkTag.setAttribute('href', canonicalUrl);
				document.head.appendChild(newLinkTag);
			}		
	}, [title, description]);
};

export default useMetaTags;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PropertyList from './Components/PropertyList';
import PropertyDetails from './Components/PropertyDetails'; // Import the new component
import Welcome from './Components/welcome';
import Featured from './Components/featured';
import Blog from './Components/blog';
import Footer from './Components/footer';
import Partners from './Components/partners';
import HomeBanner from './Components/homebanner';
import About from './Components/about';
import Services from './Components/services';
import Contact from './Components/contact';
import BlogFeatured from './Components/blogfeatured';
import BlogItem from './Components/blogitem';
import Complaints from './Components/complaints';
import Privacy from './Components/privacy';
import DataConsent from './Components/dataconsent';
import CookiePolicy from './Components/cookiepolicy';
import NewHomes from './Components/newhomes';
import YouTube, { YouTubePlayer, YouTubeProps } from 'react-youtube';
import { onPlayerReady, onPlayerStateChange, opts } from './Constants/constants';

const App = () => {
	 const excluded: number = 99;


	return (
		// <Router>
		<Routes>
			<Route
				path="/"
				element={
					<>
						<HomeBanner />
						<Welcome />
						<Featured />
						<BlogFeatured excludeId={excluded} />
						<Partners />
						<Footer />
					</>
				}
			/>
			<Route
				path="/search-results"
				element={
					<>
						<PropertyList />
						<Footer />
					</>
				}
			/>
			<Route
				path="/property/:id"
				element={
					<>
						<PropertyDetails />
						<Footer />
					</>
				}
			/>
			<Route
				path="/about"
				element={
					<>
						<About />
						<Featured />
						<Footer />
{/* 						<YouTube
							videoId={'TK72q8b_G6Y'} // defaults -> null
							id={'bandtPlayer'} // defaults -> null
							//className={} // defaults -> null
							//containerClassName={string} // defaults -> ''
							title={'Blake & Thickbroom'} // defaults -> null
							opts={opts} // defaults -> {}
							onReady={onPlayerReady}
							onStateChange={onPlayerStateChange}
							// onReady={func} // defaults -> noop
							// onPlay={func} // defaults -> noop
							// onPause={func} // defaults -> noop
							// onEnd={func} // defaults -> noop
							// onError={func} // defaults -> noop
							// onStateChange={func} // defaults -> noop
							// onPlaybackRateChange={func} // defaults -> noop
							// onPlaybackQualityChange={func} // defaults -> noop
						/> */}
					</>
				}
			/>
			<Route
				path="/services"
				element={
					<>
						<Services />
						<Featured />
						<Footer />
					</>
				}
			/>
			<Route
				path="/new-homes"
				element={
					<>
						<NewHomes />
						<Featured />
						<Footer />
					</>
				}
			/>
			<Route
				path="/blog"
				element={
					<>
						<Blog />
						<Footer />
					</>
				}
			/>
			<Route
				path="/blog/:idAndTitle"
				element={
					<>
						<BlogItem />

						<Footer />
					</>
				}
			/>
			<Route
				path="/contact"
				element={
					<>
						<Contact />
						<Footer />
					</>
				}
			/>
			<Route
				path="/in-house-complaints-procedure"
				element={
					<>
						<Complaints />
						<Footer />
					</>
				}
			/>
			<Route
				path="/privacy"
				element={
					<>
						<Privacy />
						<Footer />
					</>
				}
			/>
			<Route
				path="/data-consent"
				element={
					<>
						<DataConsent />
						<Footer />
					</>
				}
			/>
			<Route
				path="/cookie-policy"
				element={
					<>
						<CookiePolicy />
						<Footer />
					</>
				}
			/>
		</Routes>
		// </Router>
	);
};

export default App;

function Partners() {
	return (
		<section className="our-partners pt0">
			<div className="container">
				<div className="row wow fadeInUp">
					<div className="row i-am-centered">
						<div className="offset-lg-1 col-lg-2">
							<div className="item">
								<div className="partner_item">
									<img
										className="wa m-auto"
										src="/images/partners/Rightmove_logo_DEC2016.png"
										alt="1.png"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="item">
								<div className="partner_item">
									<img
										className="wa m-auto"
										src="/images/partners/OTM_Standard_RGB-DBlue.png"
										alt="1.png"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="item">
								<div className="partner_item">
									<img
										className="wa m-auto"
										src="/images/partners/NAEA-Propertymark-Protected-1024x373.jpg"
										alt="1.png"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="item">
								<div className="partner_item">
									<img
										className="wa m-auto"
										src="/images/partners/the-property-ombudsman-1024x356.png"
										alt="1.png"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-2">
							<div className="item">
								<div className="partner_item">
									<img
										className="wa m-auto"
										src="/images/partners/TSI-Appoved-Code-1190x600.png"
										alt="1.png"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Partners;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Property } from '../Models/propertyModel';
import { API_BASE_URL } from '../Constants/constants';
import FilterModal from './searchfilter';
import useMetaTags from './metatags';
import { useLocation } from 'react-router-dom';

type PriceRange = {
	min: string;
	max: string;
};

type Filters = {
	listingStatus: string;
	propertyTypes: string;
	priceRange: PriceRange;
	bedrooms: string;
	sortOrder: string;
};

	const PropertyList: React.FC = () => {
		useMetaTags(
			'For Sale | For Rent | Blake &amp; Thickbroom',
			'Search results - proeprties for sale or rent in Clacton, Holland, Frinton &amp; Tendring'
		);

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [properties, setProperties] = useState<Property[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [totalItems, setTotalItems] = useState<number>(0);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const initialFilters = {
		listingStatus: searchParams.get('listingStatus') || 'RS',
		propertyTypes: searchParams.get('propertyTypes') || '',
		priceRange: {
			min: searchParams.get('minPrice') || '0',
			max: searchParams.get('maxPrice') || '800000',
		},
		bedrooms: searchParams.get('bedrooms') || '1',
		sortOrder: searchParams.get('sortOrder') || 'HL',
	};

	const [filters, setFilters] = useState<Filters>(initialFilters);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get<any>(`${API_BASE_URL}api/Properties`, {
					params: {
						page: currentPage,
						pageSize: 9,
						listingStatus: filters.listingStatus,
						propertyTypes: filters.propertyTypes,
						minPrice: filters.priceRange.min,
						maxPrice: filters.priceRange.max,
						bedrooms: filters.bedrooms,
						sortOrder: filters.sortOrder,
					},
				});
				setProperties(response.data.items);
				setTotalPages(response.data.totalPages);
				setTotalItems(response.data.totalItems);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setError('Error fetching data. Please try again.');
				setLoading(false);
			}
		};

		fetchData();
	}, [currentPage, filters]);

	const handleNextPage = () => {
		setCurrentPage((prevPage) => prevPage + 1);
		const targetElement = document.getElementById('proplist');
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const handlePrevPage = () => {
		setCurrentPage((prevPage) => prevPage - 1);
	};

	const handleFilterSubmit = (newFilters: Filters) => {
		console.log('Filters submitted:', newFilters);
			setFilters(newFilters);
		};

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error || !properties || properties.length === 0) {
		return (
			<div className="container mt150 pb90 bgc-f7">
				<section>
					<div className="container">
						<div
							className="row">
							<div className="col-lg-12">
								<div className="breadcumb-style1">
									<h2 className="title">{error || 'No properties found'}</h2>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}

	return (
		<div
			id="mobile">
			<section className="breadcumb-search p-0">
				<div className="container">
					<div
						className="row"
						id="proplist">
						<div className="col-lg-12">
							<div className="breadcumb-style1">
								<h2 className="title">Browse Our Latest Properties</h2>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="pt20 pb90 bgc-f7">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 d-lg-block">
							<div className="dropdown-lists">
								<ul className="p-0 text-center text-xl-start">
									<li className="list-inline-item position-relative">
										<button
											type="button"
											className="open-btn mb15"
											data-bs-toggle="modal"
											data-bs-target="#SearchModal">
											<i className="flaticon-settings me-2"></i> Search Options
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						{properties.map((property) => (
							<div
								className="col-sm-6 col-lg-4"
								key={property.property_Id}>
								<div className="listing-style1">
									<div className="list-thumb">
										<img
											className="w-100"
											src={property.picture_URL_1}
											alt=""
										/>
										{property.availability_Text && (
											<div className="list-tag fz12">
												{property.availability_Text}
											</div>
										)}
										<div className="list-price">
											<div
												dangerouslySetInnerHTML={{
													__html: property.price_Text,
												}}
											/>
										</div>
									</div>
									<div className="list-content">
										<h6 className="list-title minh50">
											<a href={`/property/${property.property_Id}`}>
												<strong>
													<div
														dangerouslySetInnerHTML={{
															__html: property.address,
														}}
													/>
												</strong>
											</a>
										</h6>
										<a href={`/property/${property.property_Id}`}>
											{property.property_Type_Text}
										</a>
										<div className="pt10 list-meta d-flex align-items-center">
											<a href={`/property/${property.property_Id}`}>
												<span className="flaticon-bed"></span>
												{property.bedroom_Qty} bed
											</a>
											<a href={`/property/${property.property_Id}`}>
												<span className="flaticon-shower"></span>
												{property.bathroom_Qty} bath
											</a>
											<a href={`/property/${property.property_Id}`}>
												<span className="flaticon-home-1"></span>
												{property.reception_Qty} reception
											</a>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="row">
						<div className="mbp_pagination text-center">
							<ul className="page_navigation">
								<li className="page-item">
									<button
										onClick={handlePrevPage}
										disabled={currentPage === 1}
										className="page-link">
										{' '}
										<span className="fas fa-angle-left"></span>
									</button>
								</li>
								<li className="page-item">
									{' '}
									{currentPage} of {totalPages}{' '}
									<p className="mt10 pagination_page_count text-center">
										{totalItems} properties available
									</p>
								</li>
								<li className="page-item">
									<button
										onClick={handleNextPage}
										disabled={currentPage === totalPages}
										className="page-link">
										<span className="fas fa-angle-right"></span>
									</button>
								</li>
							</ul>
							<p className="mt10 pagination_page_count text-center">
								{totalItems} properties available
							</p>
						</div>
					</div>
				</div>
			</section>
			<FilterModal onSubmit={handleFilterSubmit} />
		</div>
	);
};

export default PropertyList;

import { YouTubeProps } from "react-youtube";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_KEY = 'a729c402-98b9-4bac-a3eb-6c42d201d999';

export const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.playVideo();
    event.target.setLoop(true);
};

export const onPlayerStateChange: YouTubeProps['onStateChange'] = (event) => {
	event.target.playVideo();
	event.target.setLoop(true);
};

export const opts = {
	height: '859',
	width: '1920',
	playerVars: {
		autoplay: 1,
        loop: 1,
		controls: 0,
		rel: 0,
		playsinline: 1,
		enablejsapi: 1,
		origin: 'https%3A%2F%2Fblake-thickbroom.co.uk',
		widgetid: 1,
	},
};

export const options = {
	height: '390',
	width: '640',
	playerVars: {
		autoplay: 1,
		controls: 1,
	},
};
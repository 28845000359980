
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../Constants/constants";
import { Property } from "../Models/propertyModel";
import axios from "axios";

const Featured: React.FC = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [properties, setProperties] = useState<Property[]>([]);

	useEffect(() => {
		let isMounted = true; // track if the component is still mounted

		const fetchData = async () => {
			try {
				const response = await axios.get<any>(
					`${API_BASE_URL}api/Properties/exclude?excludeId=0&recordCount=3&random=true`
				);
				if (isMounted) {
					// only update state if the component is still mounted
					setProperties(response.data || []);
					setLoading(false);
				}
			} catch (error) {
				if (isMounted) {
					// only update state if the component is still mounted
					console.error('Error fetching data:', error);
					setError('Error fetching data. Please try again.');
					setLoading(false);
				}
			}
		};

		fetchData();
		return () => {
			isMounted = false; // cleanup function to set isMounted to false when component unmounts
		};
	}, []);

	return (
		<section className="bgc-f7">
			<div className="container">
				<div
					className="row align-items-center wow fadeInUp"
					data-wow-delay="100ms">
					<div className="col-lg-9">
						<div className="main-title2">
							<p className="paragraph">LOOKING FOR A NEW PROPERTY?</p>
							<h2 className="title">Browse Our New Properties</h2>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="text-start text-lg-end mb-3">
							<a
								className="ud-btn2"
								href="/search-results/">
								See All Properties<i className="fal fa-arrow-right-long"></i>
							</a>
						</div>
					</div>
				</div>
				<div
					className="row wow fadeInUp"
					data-wow-delay="100ms">
					<div>
						{loading ? (
							<p>Loading...</p>
						) : error ? (
							<p>{error}</p>
						) : (
							<div className="row">
								{properties.length === 0 ? (
									<p>No properties found</p>
								) : (
									properties.map((property) => (
										<div
											className="col-sm-6 col-lg-4"
											key={property.property_Id}>
											<div className="listing-style1">
												<div className="list-thumb">
													<img
														className="w-100"
														src={property.picture_URL_1}
														alt=""
													/>
													{property.availability_Text && (
														<div className="list-tag fz12">
															{property.availability_Text}
														</div>
													)}
													<div className="list-price">
														<div
															dangerouslySetInnerHTML={{
																__html: property.price_Text,
															}}
														/>
													</div>
												</div>
												<div className="list-content">
													<h6 className="list-title minh50">
														<a href={`/property/${property.property_Id}`}>
															<strong>
																<div
																	dangerouslySetInnerHTML={{
																		__html: property.address,
																	}}
																/>
															</strong>
														</a>
													</h6>
													<a href={`/property/${property.property_Id}`}>
														{property.property_Type_Text}
													</a>
													<div className="pt10 list-meta d-flex align-items-center">
														<a href={`/property/${property.property_Id}`}>
															<span className="flaticon-bed"></span>
															{property.bedroom_Qty} bed
														</a>
														<a href={`/property/${property.property_Id}`}>
															<span className="flaticon-shower"></span>
															{property.bathroom_Qty} bath
														</a>
														<a href={`/property/${property.property_Id}`}>
															<span className="flaticon-home-1"></span>
															{property.reception_Qty} reception
														</a>
													</div>
												</div>
											</div>
										</div>
									))
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Featured;

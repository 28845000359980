import useMetaTags from './metatags';

function Complaints() {
	
	useMetaTags(
		'In House Complaints Procedure - Blake &amp; Thickbroom',
		'We are committed to providing a professional service to all our clients and customers.  When something goes wrong, we need you to tell us about it.'
	);	
	return (
		<div>
			<section className="breadcumb-section2 p-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcumb-style1">
								<h2 className="title">In House Complaints Procedure</h2>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-12">
							<h2>In House Complaints Procedure</h2>
						</div>
						<div className="col-lg-12">
							<p className="text mb25">
								We are committed to providing a professional service to all our
								clients and customers. When something goes wrong, we need you to
								tell us about it. This will help us to improve our standards.
							</p>
							<p className="text mb25">
								We will where appropriate, make reasonable adjustments for
								consumers who might be disadvantaged because of factors such as
								their age, infirmity, disability, lack of knowledge, lack of
								linguistic or numeracy ability, economic circumstances,
								bereavement or do not speak English as a first language.
							</p>
							<p className="text mb25">
								If you have a complaint, please put it in writing, including as
								much detail as possible. We will then respond in line with the
								timeframes set out below (if you feel we have not sought to
								address your complaints within eight weeks, you may be able to
								refer your complaint to the Property Ombudsman to consider
								without our final viewpoint on the matter).
							</p>
						</div>
						<div className="col-lg-12">
							<h3>What will happen next?</h3>
						</div>
						<div className="col-lg-12">
							<ul>
								<li>
									We will send you written acknowledgment of the receipt of your
									complaint within three working days of receiving it, enclosing
									a copy of this procedure.
								</li>
								<li>
									We will then investigate your complaint. This will normally be
									dealt with by the office manager who will review your file and
									speak to the member of staff who dealt with you. A formal
									written outcome of our investigation will be sent to you
									within 15 working days of receipt of the original complaint.
								</li>
								<li>
									If, at this stage, you are still not satisfied, you should
									contact us again and we will arrange for a separate review to
									take place by a senior member of staff.
								</li>
								<li>
									We will write to you within 15 working days of receiving your
									request for a review, confirming our final viewpoint on the
									matter.
								</li>
								<li>
									If you are still not satisfied with our final viewpoint (or
									more than 8 weeks has elapsed since the complaint was first
									made) you can request an independent review from The Property
									Ombudsman without charge.
								</li>
							</ul>
							<p className="mb25 fwb">
								The Property Ombudsman
								<br />
								Milford House
								<br />
								43-55 Milford Street
								<br />
								Salisbury
								<br />
								Wiltshire
								<br />
								SP1 2BP
							</p>
							<p className="mb25 fwb">01722 333 306</p>
							<p className="mb25 fwb">
								<a href="tel:admin@tpos.co.uk">admin@tpos.co.uk</a>
							</p>
							<p className="mb25 fwb">
								<a
									href="www.tpos.co.uk"
									target="_blank"
									rel="noreferrer">
									www.tpos.co.uk
								</a>
							</p>
							<p className="mb25">
								<strong>Please note the following: </strong>
								<br />
								You will need to submit your complaint to The Property Ombudsman
								within 12 months from the date of our final viewpoint, including
								any evidence to support your case. The Property Ombudsman
								requires that all complaints are addressed through this in-house
								complaints procedure, before being submitted for an independent
								review.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Complaints;

import { blogData } from '../blogData';
import { useParams } from 'react-router-dom';
import BlogFeatured from './blogfeatured';
import useMetaTags from './metatags';

function BlogItem() {

	const { idAndTitle } = useParams<Record<string, string | undefined>>();
	const idString = idAndTitle?.split('_')[0] ?? 'Unknown ID';
	const id = parseInt(idString, 10);
	const blog = blogData.find((item) => item.id === id);
	useMetaTags(blog?.title, blog?.intro);
	
	if (id === null) {
		return <div>Blog not found</div>;
	}

	return (
		<div>
			{blog && (
				<div>
					<section className="breadcumb-blogitem p-0">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 mt50">
									<div id="mobile" className="breadcumb-style1">
										<h2 className="title">{blog.title}</h2>
										<p>{blog.intro}</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="our-blog">
						<div
							className="mx-auto maxw1200 mt60 wow fadeInUp"
							data-wow-delay="100ms">
							<div className="row">
								<div className="col-lg-12">
									<div className="large-thumb">
										<img
											className="w-100"
											src={blog.image}
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							<div
								className="roww wow fadeInUp"
								data-wow-delay="500ms">
								<div className="col-xl-10 offset-xl-1">
									<div className="ui-content mt40 mb60">
										<div
											dangerouslySetInnerHTML={{
												__html: blog.content,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			)}
			<BlogFeatured excludeId={id} />
		</div>
	);
}

export default BlogItem;

import useMetaTags from './metatags';
function Services() {
	useMetaTags(
		'Estate Agent Services | Clacton | Blake &amp; Thickbroom',
		'Estate Agent Services - We are proud to boast an unrivalled level of expertise, knowledge and professionalism throughout our entire team.'
	);

	return (
		<div>
			<section className="breadcumb-section2 p-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcumb-style1">
								<h2 className="title">Leaders in Property Across Tendring</h2>
								<div className="breadcumb-list">
									Choose experience, Choose success
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="300ms">
						<div className="col-lg-6">
							<h2>Our Services</h2>
							<img
								className="w-100"
								src="/images/services_76405367_xl-2015123-1024x692.jpg"
								alt=""
							/>
						</div>
						<div className="col-lg-6">
							<div className="elementor-widget-container">
								<p>
									Blake &amp; Thickbroom is a well-established and highly
									reputable estate agency located in the picturesque county of
									Essex.{' '}
									<a href="https://blake-thickbroom.co.uk/about/">
										Our team of experienced estate agents
									</a>{' '}
									is equipped with an in-depth understanding of the local
									property market and is dedicated to helping our clients
									achieve their unique property goals.
								</p>
								<p>
									At Blake &amp; Thickbroom, we offer an extensive range of
									quality property services in Clacton and Holland-on-Sea. Our
									bespoke services, which include{' '}
									<a href="https://blake-thickbroom.co.uk/search-results/?status%5B%5D=for-sale">
										sales
									</a>
									,{' '}
									<a href="https://blake-thickbroom.co.uk/search-results/?status%5B%5D=rent">
										lettings
									</a>
									, property management, and valuations, are designed to cater
									to the unique needs of our esteemed clients. We pride
									ourselves on providing a seamless experience, ensuring that
									our clients’ property journey is as smooth and enjoyable as
									possible.
								</p>
								<p>
									We understand that{' '}
									<a href="https://blake-thickbroom.co.uk/properties/">
										buying a property
									</a>{' '}
									is a significant financial commitment that requires careful
									consideration. Therefore, we have partnered with an{' '}
									<a href="https://blake-thickbroom.co.uk/about/">
										independent mortgage team
									</a>{' '}
									to offer our clients expert advice on mortgages across the
									entire market. This allows our clients to make informed
									decisions about their finances when purchasing a property,
									thus enabling them to make the most of their investments.
								</p>
								<p>
									At Blake &amp; Thickbroom, we are passionate about delivering
									exceptional customer service and always putting our clients’
									needs first. Our team of dedicated estate agents is committed
									to making the property journey a stress-free and enjoyable
									experience for our clients. We understand that the process can
									be overwhelming, which is why we are always willing to go the
									extra mile to ensure our clients’ satisfaction.
								</p>{' '}
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pt-0">
				<div className="cta-banner3 bgc-thm-light mx-auto maxw1600 pt100 pt60-lg pb90 pb60-lg bdrs24 position-relative overflow-hidden mx20-lg">
					<div className="container">
						<div className="row">
							<div
								className="col-md-7 col-lg-7 pl30-md pl15-xs wow fadeInRight"
								data-wow-delay="500ms">
								<div className="mb30">
									<h2 className="title text-capitalize">
										How We Can Help You?
									</h2>
								</div>
								<div className="why-chose-list style2">
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-sales.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Sales</h6>
											<p className="text mb-0 fz15">
												We offer the best Sales service on the market; an easy
												process with full advice and support to help buyers and
												sellers to achieve their goals.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-lettings.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Lettings</h6>
											<p className="text mb-0 fz15">
												We provide unrivalled lettings arrangements for
												landlords and tenants throughout the Tendring area, down
												to the finest detail.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-mortgages.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Mortgages</h6>
											<p className="text mb-0 fz15">
												Our independent mortgage team are always on hand to
												offer expert advice for mortgages across the whole
												market for buyers and sellers.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-national.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">National Networking</h6>
											<p className="text mb-0 fz15">
												As a selected leading estate agent, we have access to
												potential corporate relocating buyers and sellers from
												all over the UK.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-mortgages.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Vacant Property Inspections</h6>
											<p className="text mb-0 fz15">
												Guaranteed weekly inspections of vacant properties for
												security and insurance purposes for solicitors & asset
												management companies.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-land.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Land & New Homes</h6>
											<p className="text mb-0 fz15">
												We're proud to be the market leader for the sale of New
												Homes for the whole of the Clacton-on-Sea area and
												surrounding villages.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="cta-banner2 bgc-thm maxw1600 mx-auto pt100 pt50-md pb85 pb50-md px30-md bdrs12 position-relative">
					<div className="img-box-5">
						<img
							className="img-1 spin-right"
							src="/images/about/element-1.png"
							alt=""
						/>
					</div>
					<div className="img-box-6">
						<img
							className="img-1 spin-left"
							src="/images/about/element-2.png"
							alt=""
						/>
					</div>
					<div
						className="cta-style2 d-none d-lg-block wow fadeInRight"
						data-wow-delay="300ms">
						<img
							src="/images/freevaluation.jpg"
							alt=""
						/>
					</div>
					<div className="container">
						<div className="row">
							<div
								className="col-lg-7 col-xl-5 wow fadeInUp"
								data-wow-delay="500ms">
								<div className="cta-style2">
									<h2 className="cta-title">
										Get a Free Instant Property Valuation
									</h2>
									<p className="cta-text">
										We offer instant online valuations, just enter your postcode
										below for an indication of what your property is worth. You
										can also get a lettings valuation to see what rental you can
										achieve for your rental property.
									</p>
									<a
										href="https://valuation.blake-thickbroom.co.uk/home/3302"
										target="_blank"
										rel="noreferrer"
										className="ud-btn btn-dark mt10">
										Free Instant Valuation
										<i className="fal fa-arrow-right-long"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="row">
					<div
						className="col-lg-6 m-auto wow fadeInUp"
						data-wow-delay="300ms">
						<div className="main-title text-center">
							<h2 className="title">Helpful Downloads</h2>
							<p className="paragraph">Documents</p>
						</div>
					</div>
				</div>
				<div className="row downloads">
					<div
						className="col-sm-6 col-lg-6 wow fadeInLeft"
						data-wow-delay="00ms">
						<div className="iconbox-style2 text-center">
							<div className="icon">
								<img
									src="/images/icon/property-buy.svg"
									alt=""
								/>
							</div>
							<div className="iconbox-content">
								<h4 className="title">Tenant Fees</h4>
								<p className="text">
									Our staff are specially trained and equipped to assist tenants
									to find properties to rent that will meet their needs. We
									maintain a database of properties to rent and of prospective
									tenants and their requirements.
								</p>
								<a
									href="/NEW-TENANT-PACK-INFORMATION-FORM-SEP-21.pdf"
									target="_blank"
									className="ud-btn btn-white2">
									Download<i className="fal fa-arrow-right-long"></i>
								</a>
							</div>
						</div>
					</div>
					<div
						className="col-sm-6 col-lg-6 wow fadeInRight"
						data-wow-delay="300ms">
						<div className="iconbox-style2 text-center">
							<div className="icon">
								<img
									src="/images/icon/property-rent.svg"
									alt=""
								/>
							</div>
							<div className="iconbox-content">
								<h4 className="title">Landlord Fees</h4>
								<p className="text">
									We are a local, independent firm of Estate Agents specialising
									in Residential Sales and Lettings and are fully focused on the
									priorities and needs of the Landlord.
								</p>
								<a
									href="/NEW-LANDLORDS-PACK-SEP-21.pdf"
									target="_blank"
									className="ud-btn btn-white2">
									Download<i className="fal fa-arrow-right-long"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Services;

import useMetaTags from './metatags';

function Privacy() {

useMetaTags(
	'Privacy Policy | Blake &amp; Thickbroom',
	'Blake &amp; Thickbroom Privacy Policy'
);

	return (
		<div>
			<section className="breadcumb-section2 p-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="breadcumb-style1">
								<h2 className="title">Privacy Policy</h2>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-12">
							<h2>Precedent Website Privacy Notice</h2>
						</div>
						<div className="col-lg-12">
							<div className="page-content-wrap">
								<p>
									<strong>Data Protection - Privacy Policy</strong>
								</p>
								<p>
									We respect your privacy, and want to be clear about how we
									handle information about you.
								</p>
								<p>
									<strong>What information may we collect about you?</strong>
								</p>
								<p>
									We may collect personal data about you in the course of our
									work. That may include things like:
								</p>
								<ul>
									<li>Your contact details.</li>
									<li>Your date of birth.</li>
									<li>Where you work.</li>
									<li>
										Your wishes and criteria in any transaction you are
										considering.
									</li>
									<li>
										Financial information, for example about your income and
										capital resources.
									</li>
								</ul>
								<p>
									Usually we will have received this directly from you. However
									we may also receive personal data about you from others, such
									as from the other party to a transaction you are engaged in or
									from joint agents. Family members also sometimes provide
									information about each other.
								</p>
								<p>
									<strong>How will we use the information?</strong>
								</p>
								<p>
									Our use of such personal data is subject to data protection
									law.
								</p>
								<p>
									We mainly use your personal data to provide you with
									information or services you have requested. We may also use it
									for any other purpose for which you give your consent. For
									example we may send you additional information about the firm
									or its services, if you have consented to us doing so.
								</p>
								<p>
									We may also use it for other normal purposes connected with
									our work. For example we will use your information to update
									our own business records, complete statutory returns, and
									otherwise comply with our regulatory obligations.
								</p>
								<p>
									<strong>
										Will we share information about you with anyone?
									</strong>
								</p>
								<p>
									We take your privacy seriously. We will never sell your
									personal data to anyone, and we take precautions to keep it
									secure.&nbsp; It will sometimes be a normal and necessary part
									of our work to pass on information to third parties. For
									example:
								</p>
								<ul>
									<li>
										We may pass on information about you to solicitors to help
										them proceed with a transaction in which you are involved.
									</li>
									<li>
										If you are a prospective tenant, we may pass on references
										about you to the landlord.
									</li>
									<li>
										We may inform utility companies of changes in the occupiers
										of property.
									</li>
									<li>
										We may pass on information to maintenance and repair
										contractors, in respect of property where we have
										responsibilities.
									</li>
									<li>
										We may need to pass on information to those who help collect
										outstanding accounts.
									</li>
								</ul>
								<p>
									In addition our practice may be audited or checked by third
									parties such as our accountants, which may enable them to see
									some information about you. Such third parties are required to
									maintain confidentiality in relation to your information.
								</p>
								<p>
									<strong>
										Data Protection in Respect of Money Laundering Checks
									</strong>
								</p>
								<p>
									Any personal data we receive from you for the purposes of our
									money laundering checks will be processed only for the
									purposes of preventing money laundering and terrorist
									financing, or as otherwise permitted by law or with your
									express consent.
								</p>
								<p>
									You consent to us retaining such data for longer than the five
									year statutory period, unless you tell us otherwise.
								</p>
								<p>
									<strong>Your rights</strong>
								</p>
								<p>
									You have a right of access under data protection law to the
									personal data that we hold about you. We seek to keep that
									personal data correct and up to date. You should let us know
									if you believe the information we hold about you needs to be
									corrected or updated.
								</p>
								<p>
									<strong>Your Acceptance&nbsp;</strong>By using this website,
									you consent to the collection and use of information by Blake
									&amp; Thickbroom Estate Agents in accordance with our Privacy
									Policy.&nbsp; If you do not agree to this policy, please do
									not use our site.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Privacy;

function Footer() {
  return (
		<section className="footer-style1 pt60 pb-0">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="footer-widget mb-4 mb-lg-5">
							<a
								className="footer-logo"
								href="/">
								<img
									className="mb40"
									src="/images/footer-blake-and-thickbroom-residential.png"
									alt="Blake &amp; Thickbroom"
								/>
							</a>
							<div className="row mb-lg-5">
								<div className="col-auto">
									<div className="contact-info">
										<p className="info-title">Call us</p>
										<h6 className="info-phone">
											<a href="tel:01255 688 788">01255 688 788</a>
										</h6>
									</div>
								</div>
								<div className="col-auto">
									<div className="contact-info">
										<p className="info-title">Email us</p>
										<h6 className="info-mail">
											<a href="mailto:enquiries@blake-thickbroom.co.uk">
												enquiries@blake-thickbroom.co.uk
											</a>
										</h6>
									</div>
								</div>
							</div>
							<div>
								<p className="info-title mb-4">
									We are proud to have over 125 years' combined experience in
									selling properties. The four partners of this company - Tracey
									Blake, Steve Thickbroom, John Gallant, Mark Richardson - each
									have the local knowledge and maturity needed to run a
									successful Estate Agency.
								</p>
							</div>
							<div className="social-widget">
								<div className="social-style1">
									<a
										href="https://www.facebook.com/BlakeandThickbroom/?locale=en_GB"
										target="_blank">
										<i className="fab fa-facebook-f list-inline-item"></i>
									</a>
									<a
										href="https://www.instagram.com/blakeandthickbroom/"
										target="_blank">
										<i className="fab fa-instagram list-inline-item"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="footer-widget mb-4 mb-lg-5">
							<div className="row justify-content-between">
								<div className="col-auto">
									<div className="link-style1 mb-3">
										<h6 className="text-white mb25">Popular Search</h6>
										<div className="link-list">
											<a href="/search-results/?page=1&pageSize=10&listingStatus=RS">
												For Sale
											</a>
											<a href="/search-results/?page=1&pageSize=10&listingStatus=RL">
												For Rent
											</a>
											<a href="/search-results/?page=1&pageSize=10&listingStatus=NW&propertyTypes=">
												New Homes
											</a>
										</div>
									</div>
								</div>
								<div className="col-auto">
									<div className="link-style1 mb-3">
										<h6 className="text-white mb25">Useful Links</h6>
										<ul className="ps-0">
											<li>
												<a href="/cookie-policy">Cookie Policy</a>
											</li>
											<li>
												<a href="/data-consent">Data Consent</a>
											</li>
											<li>
												<a href="/privacy">Privacy Policy</a>
											</li>
											<li>
												<a href="/in-house-complaints-procedure">
													In House Complaints Procedure
												</a>
											</li>
											<li>
												<a
													href="/UKALA-CMP-Certificate.pdf"
													target="_blank"
													rel="noreferrer">
													Client Money Protection
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container white-bdrt1 py-4">
				<div className="row">
					<div className="col-sm-6">
						<div className="text-center text-lg-start">
							<p className="copyright-text text-gray ff-heading">
								&copy; Blake & Thickbroom - Created by{' '}
								<a
									href="https://www.spiralsites.com/"
									target="_blank"
									rel="noreferrer">
									Spiral Sites
								</a>
							</p>
						</div>
					</div>
					<div className="col-sm-6">
						<div className="text-center text-lg-end">
							<p className="footer-menu ff-heading text-gray">
								<a
									className="text-gray"
									href="/">
									Privacy
								</a>{' '}
								·{' '}
								<a
									className="text-gray"
									href="/">
									Terms
								</a>{' '}
								·{' '}
								<a
									className="text-gray"
									href="/">
									Sitemap
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Footer;

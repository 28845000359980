import {blogData} from "../blogData";

interface BlogFeaturedProps {
	excludeId: number;
}

const getRandomBlogs = (
	blogData: any[],
	excludeId: number,
	count: number
): any[] => {
  const filteredBlogs = blogData.filter((blog) => blog.id !== excludeId);
	for (let i = filteredBlogs.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[filteredBlogs[i], filteredBlogs[j]] = [filteredBlogs[j], filteredBlogs[i]];
	}
	return filteredBlogs.slice(0, count);
};

const BlogFeatured: React.FC<BlogFeaturedProps> = ({ excludeId }) => {
	const blogsToShow = getRandomBlogs(blogData, excludeId, 3);

	return (
		<section className="pb90 pb20-md">
			<div className="container">
				<div className="row">
					<div
						className="col-lg-6 m-auto wow fadeInUp"
						data-wow-delay="100ms">
						<div className="main-title text-start text-md-center">
							<h2 className="title">From Our Blog</h2>
							<p className="paragraph">
								See the our latest news and information...
							</p>
						</div>
					</div>
				</div>
				<div
					className="row wow fadeInUp"
					data-wow-delay="100ms">
					{blogsToShow.map((blog) => (
						<div
							className="col-sm-6 col-lg-4"
							key={blog.id}>
							<div className="blog-style1">
								<div className="blog-img">
									<img
										className="w-100"
										src={blog.image}
										alt={blog.title}
									/>
								</div>
								<div className="blog-content">
									<div className="date">
										<span className="month">{blog.month}</span>
										<span className="day">{blog.day}</span>
									</div>
									<h6 className="title mt-1">
										<a
											href={`/blog/${blog.id}_${encodeURIComponent(
												blog.title
											)}`}>
											{blog.title}
										</a>
									</h6>
									<p>{blog.intro}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default BlogFeatured;

function Welcome() {
	return (
		<section className="pt0 pb40-md">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-xl-12">
						<div
							className="about-box-1 pe-4 mt10 mt0-lg mb30-lg wow fadeInRight"
							data-wow-delay="300ms">
							<h2 className="title mb30">
								Welcome to Blake & Thickbroom Residential
							</h2>
							<p className="text mb25 fz15">
								If you want to buy or sell property in Clacton or Tendring, look
								no further than Blake & Thickbroom. With over 125 years of
								combined experience selling properties in the area, we are
								considered one of the top property agents in Tendring and offer
								a comprehensive range of services to ensure your property sale
								is as smooth as possible.
							</p>
							<p className="text mb25 fz15">
								Our team is committed to providing our clients with the highest
								level of service and satisfaction. We are constantly striving to
								stay ahead of the competition in order to give you the best
								experience possible when dealing with us. Our experienced estate
								agents will help guide you through the entire process from start
								to finish, giving you clear and concise advice throughout.
							</p>
							<p className="text mb25 fz15">
								With offices in Clacton and Holland-on-Sea, we are known for our
								professional, friendly service and commitment to helping clients
								achieve their property goals. The four partners of Blake &
								Thickbroom - Tracey Blake, Steve Thickbroom, John Gallant, and
								Mark Richardson - are each experts in their field, with a deep
								understanding of the local property market and a wealth of
								experience in the industry.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Welcome;

import useMetaTags from './metatags';

function About() {
	
	useMetaTags(
		'About Us | Blake &amp; Thickbroom',
		'With A Wide Range Of Properties To Purchase In Clacton, Discover More About Blake &amp; Thickbroom Estate Agents &amp; Speak To Our Team Now!'
	);

	return (
		<div>
			<section className="breadcumb-section2 p-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="breadcumb-style1">
								<h2 className="title about">About Us</h2>
								<p className="breadcumb-list">
									With an extensive history and proven experience throughout
									Tendring, our services are built upon solid foundations.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="our-about pb90">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-6">
							<h2>Blake & Thickbroom</h2>
							<div
								className="row wow fadeInUp"
								data-wow-delay="100ms">
								<div className="col-lg-12">
									<div className="about-page-img">
										<img
											className="w-100"
											src="/images/about/about_310225692_xl-201512-1024x680.jpg"
											alt=""
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<p className="text mb25">
								Founded in the year 2000, Blake and Thickbroom have a combined
								experience of over 125 years in selling quality properties in
								Essex between our four partners: Tracey Blake, Steve Thickbroom,
								John Gallant and Mark Richardson.
							</p>

							<p className="text mb25">
								With prominent office locations in both Clacton-on-Sea and
								Holland-on-Sea, we aim to provide a high level of service in all
								areas. All members of our team are mature, professional,
								competent and fully trained.
							</p>

							<p className="text mb25">
								We can offer you short notice free valuations with realistic and
								competitive commission rates.
							</p>
							<p className="text mb25">
								We also offer comprehensive digital marketing of your property
								through our partnership with a leading marketing agency in
								Essex, and through major property websites such as Rightmove and
								OnTheMarket.
							</p>
							<p className="text mb25">
								We offer our own-in house independent financial advisor for any
								free mortgage advice required.
							</p>
							<p className="text mb25">
								Our Clacton town centre office is open seven days a week and our
								Holland on Sea office is open five days a week. We're proud to
								offer the most experienced valuation team within the Tendring
								area. Meet our team below to find out more!
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="our-about pt-0">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-12">
							<div className="about-page-img">
								<img
									className="w-100"
									src="images/about/about-page-banner.jpg"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pt-0 pb80 pb50-md">
				<div className="container">
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-lg-9 mx-auto text-center">
							<div className="main-title2">
								<h2 className="title">The Blake &amp; Thickbroom Team</h2>
								<p className="paragraph">Over 35 years of experience</p>
							</div>
						</div>
					</div>
					<div
						className="row wow fadeInUp"
						data-wow-delay="100ms">
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/Steve-Thickbroom-min.jpg"
										alt="Steve Thickbroom - Senior Partner"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingOne">
												<button
													className="accordion-button"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseOne"
													aria-expanded="false"
													aria-controls="collapseOne">
													Steve Thickbroom - Senior Partner
												</button>
											</h2>
											<div
												id="collapseOne"
												className="accordion-collapse collapse"
												aria-labelledby="headingOne"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Steve started his career in estate agency in 1987,
													gaining almost 35 years' experience. He and Tracey
													founded Blake & Thickbroom Estate Agents in 2000,
													opening a second office in Holland on Sea in 2010.
													Steve is also a member of NAEA (National Association
													of Estate Agency) and still enjoys being involved in
													the every day running of the business including
													carrying out valuations, viewings and sales
													progression. Steve has spent over 15 years
													specialising in the land and new homes side of the
													business, thus making Blake & Thickbroom leaders in
													this field locally.
													<a href="mailto:Stevethickbroom@blake-thickbroom.co.uk">
														Stevethickbroom@blake-thickbroom.co.uk
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/Tracey-Blake-min.jpg"
										alt="Tracey Blake - Senior Partner"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingTwo">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseTwo"
													aria-expanded="false"
													aria-controls="collapseTwo">
													Tracey Blake - Senior Partner
												</button>
											</h2>
											<div
												id="collapseTwo"
												className="accordion-collapse collapse"
												aria-labelledby="headingTwo"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Tracey started her career in estate agency in 1986, in
													the Clacton on Sea area, gaining over 35 years local
													experience. Tracey still enjoys being involved in the
													every day running of the business and still delights
													in meeting new, and valued former clients. She also
													spends a lot of her time behind the scenes with staff
													training, recruitment, progression and HR.
													<a href="mailto:Traceyblake@blake-thickbroom.co.uk">
														Traceyblake@blake-thickbroom.co.uk
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/David-Allis-min.jpg"
										alt="David Allis - Office Manager"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingThree">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseThree"
													aria-expanded="false"
													aria-controls="collapseThree">
													David Allis - Office Manager
												</button>
											</h2>
											<div
												id="collapseThree"
												className="accordion-collapse collapse"
												aria-labelledby="headingThree"
												data-parent="#accordionExample">
												<div className="accordion-body">
													David joined Blake & Thickbroom in 2004 and offers
													over 30 years' experience in estate agency. David
													deals with the day-to-day challenges of managing a
													busy office and as head of the valuation team he
													excels in offering our clients the professional advice
													and guidance required to ensure the best possible
													house prices are achieved.
													<a href="mailto:Davidallis@blake-thickbroom.co.uk">
														Davidallis@blake-thickbroom.co.uk
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/Paul-Collins-min.jpg"
										alt="Paul Collins - Assistant Manager"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingFour">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseFour"
													aria-expanded="false"
													aria-controls="collapseFour">
													Paul Collins - Assistant Manager
												</button>
											</h2>
											<div
												id="collapseFour"
												className="accordion-collapse collapse"
												aria-labelledby="headingFour"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Paul joined Blake & Thickbroom in 2000 only six months
													after opening. Paul is a senior member of the
													valuation team and also assists with the everyday
													running of the office. His main focus is with
													progression of sales, where he has built long standing
													and valued relationships with local solicitors
													offering an outstanding progression service.
													<div>
														<a href="mailto:Paulcollins@blake-thickbroom.co.uk">
															Paulcollins@blake-thickbroom.co.uk
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/poss.jpg"
										alt="Jack Thickbroom - Senior Negotiator / Valuer"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingFive">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseFive"
													aria-expanded="false"
													aria-controls="collapseFive">
													Jack Thickbroom - Senior Negotiator / Valuer
												</button>
											</h2>
											<div
												id="collapseFive"
												className="accordion-collapse collapse"
												aria-labelledby="headingFive"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Jack joined Blake & Thickbroom in 2019 and deals daily
													with arranging and accompanying viewings, booking
													valuations and offering advice and feedback for all
													our vendors in the ever-changing market place. to Jack
													is now part of the Valuation team and has several
													years' experience in valuing property in the area. He
													offers a positive and enthusiastic attitude when
													dealing with both buyers and sellers and is keen to
													progress to a senior level.
													<div>
														<a href="mailto:JackThickbroom@blake-thickbroom.co.uk">
															JackThickbroom@blake-thickbroom.co.uk
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/joe.jpg"
										alt="Joseph Thickbroom - Senior Negotiator"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingFivea">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseFivea"
													aria-expanded="false"
													aria-controls="collapseFivea">
													Joseph Thickbroom - Senior Negotiator
												</button>
											</h2>
											<div
												id="collapseFivea"
												className="accordion-collapse collapse"
												aria-labelledby="headingFivea"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Joseph originally joined Blake & Thickbroom in 2017
													before continuing his career in the property industry
													gaining experience in sales and mortgages. Joseph has
													now recently returned to the company as a Senior
													Negotiator after spending several months travelling.
													Joseph’s duties within the office include arranging
													and accompanying viewings, booking valuation and
													offering advice and feedback for all of our vendors in
													the ever-changing marketplace. He is currently
													training to be part of the Valuation team and offers a
													positive and enthusiastic attitude when dealing with
													both buyers and sellers. .
													<div>
														<a href="mailto:JosephThickbroom@blake-thickbroom.co.uk">
															JosephThickbroom@blake-thickbroom.co.uk
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/Sarah-Beckham-min.jpg"
										alt="Sarah Beckham - Sales Administrator"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingSix">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseSix"
													aria-expanded="false"
													aria-controls="collapseSix">
													Sarah Beckham - Sales Administrator
												</button>
											</h2>
											<div
												id="collapseSix"
												className="accordion-collapse collapse"
												aria-labelledby="headingSix"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Sarah joined Blake & Thickbroom in 2009 and is in
													charge of all administration within our Clacton office
													preparing property details and all correspondence to
													vendor and purchasers. Also assisting the sales team
													with her negotiating skills including booking
													valuations, arranging viewings and promoting
													properties.
													<div>
														<a href="mailto:SarahBeckham@blake-thickbroom.co.uk">
															SarahBeckham@blake-thickbroom.co.uk
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/Lesley-Rossiter-min.jpg"
										alt="Lesley Rossiter - Sales Administrator"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingSevern">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseSevern"
													aria-expanded="false"
													aria-controls="collapseSevern">
													Lesley Rossiter - Sales Administrator
												</button>
											</h2>
											<div
												id="collapseSevern"
												className="accordion-collapse collapse"
												aria-labelledby="headingSevern"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Lesley joined Blake & Thickbroom in 2012, Lesley is
													based at our Holland on Sea office and is in charge of
													the management of this Office, including booking
													valuations, arranging viewings and promoting
													properties in the Holland on Sea area.
													<div>
														<a href="mailto:LesleyRossiter@blake-thickbroom.co.uk">
															LesleyRossiter@blake-thickbroom.co.uk
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-auto">
							<div className="feature-style2 mb30">
								<div className="feature-img">
									<img
										className="bdrs12"
										src="/images/about/Julie-Challis-min.jpg"
										alt="Julie Challis - Negotiator"
									/>
								</div>
								<div className="accordion-style1">
									<div
										className="accordion"
										id="accordionExample">
										<div className="accordion-item">
											<h2
												className="accordion-header"
												id="headingEight">
												<button
													className="accordion-button collapsed"
													type="button"
													data-bs-toggle="collapse"
													data-bs-target="#collapseEight"
													aria-expanded="false"
													aria-controls="collapseEight">
													Julie Challis - Negotiator
												</button>
											</h2>
											<div
												id="collapseEight"
												className="accordion-collapse collapse"
												aria-labelledby="headingEight"
												data-parent="#accordionExample">
												<div className="accordion-body">
													Julie offers 7 years' experience in estate agency and
													is a newest recruit joining Blake & Thickbroom in
													2021, expanding the current sales team. Julie will be
													spending most of her time arranging viewings, booking
													valuations and offering advice and feedback for all
													our vendors in the ever-changing market place.
													<div>
														<a href="mailto:JulieChallis@blake-thickbroom.co.uk">
															JulieChallis@blake-thickbroom.co.uk
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="pt-0">
				<div className="cta-banner3 bgc-thm-light mx-auto maxw1600 pt100 pt60-lg pb90 pb60-lg bdrs24 position-relative overflow-hidden mx20-lg">
					<div className="container">
						<div className="row">
							<div
								className="col-md-7 col-lg-7 pl30-md pl15-xs wow fadeInRight"
								data-wow-delay="500ms">
								<div className="mb30">
									<h2 className="title text-capitalize">
										How We Can Help You?
									</h2>
								</div>
								<div className="why-chose-list style2">
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-sales.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Sales</h6>
											<p className="text mb-0 fz15">
												We offer the best Sales service on the market; an easy
												process with full advice and support to help buyers and
												sellers to achieve their goals.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-lettings.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Lettings</h6>
											<p className="text mb-0 fz15">
												We provide unrivalled lettings arrangements for
												landlords and tenants throughout the Tendring area, down
												to the finest detail.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-mortgages.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Mortgages</h6>
											<p className="text mb-0 fz15">
												Our independent mortgage team are always on hand to
												offer expert advice for mortgages across the whole
												market for buyers and sellers.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-national.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">National Networking</h6>
											<p className="text mb-0 fz15">
												As a selected leading estate agent, we have access to
												potential corporate relocating buyers and sellers from
												all over the UK.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-mortgages.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Vacant Property Inspections</h6>
											<p className="text mb-0 fz15">
												Guaranteed weekly inspections of vacant properties for
												security and insurance purposes for solicitors & asset
												management companies.
											</p>
										</div>
									</div>
									<div className="list-one d-flex align-items-start mb30">
										<img
											width="60"
											height="60"
											src="/images/services-land.png"
											className="attachment-thumbnail size-thumbnail"
											alt="Blake &amp; Thickbroom - Estate Agency in Clacton-on-Sea, Essex"
											loading="lazy"
										/>
										<div className="list-content flex-grow-1 ml20">
											<h6 className="mb-1">Land & New Homes</h6>
											<p className="text mb-0 fz15">
												We're proud to be the market leader for the sale of New
												Homes for the whole of the Clacton-on-Sea area and
												surrounding villages.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default About;
